<template>
	<div class="p-grid" style="padding: 0rem 0rem 0rem 3rem">
		<div class="p-col-3">
			<Card>
				<template #title>{{ $t('Identification') }}</template>
				<template #content>
					<div class="p-grid" style="min-height: 200px;">
						<div class="p-col-5"><strong>{{$t('Categorization')}}:</strong></div>
						<div class="p-col-7">{{ data['categorization_'+i18n.locale()] }}</div>
						<div class="p-col-5"><strong>{{$t('Risk likely')}}:</strong></div>
						<div class="p-col-7">{{ data['risk_provider_'+i18n.locale()] }}</div>
						<div class="p-col-5"><strong>{{$t('Risk Factors')}}:</strong></div>
						<div class="p-col-7">
							<div v-for="opc of data.factor" :key="opc.id" class="p-ml-1 p-mr-1 p-mb-1">
								{{ opc[i18n.locale()] }}
							</div>
						</div>
					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-3" v-if="step>1">
			<Card>
				<template #title>{{ $t('Analysis') }}</template>
				<template #content>
					<div class="p-grid" style="min-height: 200px;">
						<div class="p-col-5"><strong>{{$t('Likelihood')}}:</strong></div>
						<div class="p-col-7">
							<Tag style="width: 30px; height: 30px; background-color: #CECECE">
								{{data.likelihood}}
							</Tag>
							{{ data['likelihood_'+i18n.locale()] }}
						</div>
						<div class="p-col-5"><strong>{{$t('Impact')}}:</strong></div>
						<div class="p-col-7">
							<Tag style="width: 30px; height: 30px; background-color: #CECECE">
								{{data.impact}}
							</Tag>
							{{ data['impact_'+i18n.locale()] }}
						</div>
						<div class="p-col-5"><strong>{{$t('Inherent risk')}}:</strong></div>
						<div class="p-col-7">
							<Tag :style="'width: 30px; height: 30px; background-color: #'+data.score_color">
								{{data.value}}
							</Tag>
							{{ data['score_'+i18n.locale()] }}
						</div>
						<div class="p-col-5"><strong>{{$t('Risk appetite')}}:</strong></div>
						<div class="p-col-7">
							<Tag :style="'width: 30px; height: 30px; background-color: #'+data.app_color">
								{{ data['app_'+i18n.locale()] }}
							</Tag>
							{{ data['appetite_'+i18n.locale()] }}
						</div>
					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-3" v-if="step>2">
			<Card>
				<template #title>{{ $t('Planning') }}</template>
				<template #content>
					<div class="p-grid" style="min-height: 200px;">
						<div class="p-col-12">
							<strong>{{$t('Mitigating measures')}}: </strong>
							{{ data.mitigating_measures }}
						</div>
						<div class="p-col-5"><strong>{{$t('Responsible')}}:</strong></div>
						<div class="p-col-7">
							{{ data.responsible }}
						</div>
						<div class="p-col-5"><strong>{{$t('Date')}}:</strong></div>
						<div class="p-col-7">
							{{ data['date_start_'+i18n.locale()] }} - {{ data['date_end_'+i18n.locale()] }}
						</div>
						<div class="p-col-12">
							<strong>{{$t('Any findings')}}: </strong>
							{{ data.issues }}
						</div>
					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-3" v-if="step>3">
			<Card>
				<template #title>{{ $t('Monitoring') }}</template>
				<template #content>
					<div class="p-grid" style="min-height: 200px;">
						<div class="p-col-12">
							<strong>{{$t('Assessment of mitigating Measures')}}: </strong>
							{{ data['mit_cat_'+i18n.locale()] }}
						</div>
						<div class="p-col-5">
							{{ data['mitigating_'+i18n.locale()] }}
						</div>
						<div class="p-col-7">
							<Tag style="width: 60px; height: 30px; background-color: #CECECE">
								{{parseFloat(data.mit_value).toFixed(2)}}%
							</Tag>
						</div>
						<div class="p-col-5"><strong>{{$t('Net risk')}}:</strong></div>
						<div class="p-col-7">
							<Tag style="width: 60px; height: 30px; background-color: #CECECE">
								{{parseFloat(data.net_appetite).toFixed(2)}}
							</Tag>
						</div>
					</div>
				</template>
			</Card>
		</div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	export default {
		props: {
			data: {
				type: Object,
				default: null,
			},
			step: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				i18n: null,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
		},
	}
</script>